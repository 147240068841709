import React from 'react';
import {
  withMail,
  withUserDefaultSpace,
  withUserPreferences,
  withTranslation,
} from 'components/hoc';
import compose from 'lodash/fp/compose';
import { useTableSort } from 'hooks/useTableSort';

const MailRow = (props) => {
  const {
    mail,
    selectCheckbox,
    fd,
    trObj,
    isChecked,
    selectMail,
    isSelectedMail,
    type,
    columnWidths,
  } = props;

  const selectRow = () => {
    selectMail(mail?.id);
  };

  const { flags } = mail;

  return (
    <tr
      className={isSelectedMail ? 'bg-secondary rounded' : ''}
      style={{
        height: '20px',
        lineHeight: '20px',
      }}
    >
      <td
        style={{
          width: `${columnWidths.checkbox}px`,
          padding: '0 4px',
          textAlign: 'center',
          verticalAlign: 'middle',
        }}
      >
        <input
          type="checkbox"
          checked={isChecked(mail?.id)}
          onChange={(e) => {}}
          onClick={() => selectCheckbox(mail?.id)}
          style={{
            width: '14px',
            height: '14px',
            margin: 0,
            padding: 0,
            verticalAlign: 'middle',
            position: 'relative',
            top: '-1px',
          }}
        />
      </td>
      <td
        onClick={selectRow}
        className={
          flags != 'SEEN' && type === 'INBOX'
            ? 'text-truncate font-weight-bold'
            : 'text-truncate'
        }
        style={{ verticalAlign: 'middle' }}
      >
        {type === 'INBOX' ? mail?.from_mail : mail.to_mail}
      </td>
      <td
        className={
          flags != 'SEEN' && type === 'INBOX'
            ? 'text-truncate font-weight-bold'
            : 'text-truncate'
        }
        onClick={selectRow}
        style={{ verticalAlign: 'middle' }}
      >
        {mail?.subject}
      </td>
      <td
        className={
          flags != 'SEEN' && type === 'INBOX'
            ? 'text-truncate font-weight-bold'
            : 'text-truncate'
        }
        onClick={selectRow}
        style={{ verticalAlign: 'middle' }}
      >
        {fd(mail?.created_at)}
      </td>
      <td
        className={
          flags != 'SEEN' && type === 'INBOX'
            ? 'text-truncate font-weight-bold'
            : 'text-truncate'
        }
        onClick={selectRow}
        style={{ verticalAlign: 'middle' }}
      >
        {trObj(mail?.area_detail)}
      </td>
    </tr>
  );
};

const MailList = (props) => {
  const {
    mailMessages,
    tr,
    fd,
    trObj,
    selectCheckbox,
    isChecked,
    isMasterChecked,
    selectMail,
    selectedMail,
    type = 'INBOX',
  } = props;

  const { sortedItems, handleSort, SortIndicator } = useTableSort(mailMessages, {
    getValueByKey: (item, key) => {
      switch (key) {
        case 'sender':
          return type === 'INBOX' ? item.from_mail : item.to_mail;
        case 'subject':
          return item.subject;
        case 'date':
          return item.created_at;
        case 'dataArea':
          return item.area_detail;
        default:
          return item[key];
      }
    },
  });

  // Remove checkbox from column widths state
  const [columnWidths, setColumnWidths] = React.useState({
    sender: 200,
    subject: 150,
    date: 100,
    dataArea: 150,
  });

  const handleColumnResize = (column, e) => {
    e.preventDefault();
    const startX = e.pageX;
    const startWidth = columnWidths[column];

    const handleMouseMove = (moveEvent) => {
      moveEvent.preventDefault();
      const newWidth = Math.max(50, startWidth + (moveEvent.pageX - startX));
      setColumnWidths((prev) => ({
        ...prev,
        [column]: newWidth,
      }));
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <>
      <table
        className="table table-sm table-striped table-hover"
        style={{
          tableLayout: 'fixed',
          width: '100%',
          fontSize: '0.6rem',
        }}
      >
        <thead>
          <tr>
            {/* Checkbox column without resize handle */}
            <th
              style={{
                width: '30px',
                padding: '4px',
                verticalAlign: 'middle',
                height: '30px',
                lineHeight: '30px',
                position: 'relative',
              }}
              className="checkbox-column"
            >
              <input
                type="checkbox"
                checked={isMasterChecked()}
                id="mastercheck"
                onClick={() => selectCheckbox()}
                style={{
                  width: '14px',
                  height: '14px',
                  margin: 0,
                  padding: 0,
                  verticalAlign: 'middle',
                  position: 'relative',
                  top: '-1px',
                }}
              />
            </th>
            <th
              onClick={() => handleSort('sender')}
              style={{
                width: `${columnWidths.sender}px`,
                position: 'relative',
                cursor: 'pointer',
              }}
            >
              {type === 'INBOX' ? tr('Sender') : tr('Receiver')}
              <SortIndicator columnKey="sender" />
              <div
                className="resize-handle"
                onMouseDown={(e) => handleColumnResize('sender', e)}
              />
            </th>
            <th
              onClick={() => handleSort('subject')}
              style={{
                width: `${columnWidths.subject}px`,
                position: 'relative',
                cursor: 'pointer',
              }}
            >
              {tr('Subject')}
              <SortIndicator columnKey="subject" />
              <div
                className="resize-handle"
                onMouseDown={(e) => handleColumnResize('subject', e)}
              />
            </th>
            <th
              onClick={() => handleSort('date')}
              style={{
                width: `${columnWidths.date}px`,
                position: 'relative',
                cursor: 'pointer',
              }}
            >
              {tr('Date')}
              <SortIndicator columnKey="date" />
              <div
                className="resize-handle"
                onMouseDown={(e) => handleColumnResize('date', e)}
              />
            </th>
            <th
              onClick={() => handleSort('dataArea')}
              style={{
                width: `${columnWidths.dataArea}px`,
                position: 'relative',
                cursor: 'pointer',
              }}
            >
              {tr('Data Area')}
              <SortIndicator columnKey="dataArea" />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedItems.map((mail) => (
            <MailRow
              key={mail?.id}
              mail={mail}
              fd={fd}
              trObj={trObj}
              selectCheckbox={selectCheckbox}
              isChecked={isChecked}
              selectMail={selectMail}
              isSelectedMail={selectedMail?.id == mail?.id}
              type={type}
              columnWidths={columnWidths}
            />
          ))}
        </tbody>
      </table>
      <style>
        {`
          .resize-handle {
            position: absolute;
            right: -2px;
            top: 25%;
            height: 50%;
            width: 4px;
            background: transparent;
            cursor: col-resize;
            z-index: 1;
            transition: background-color 0.2s ease;
          }

          th {
            position: relative;
            border-right: none;
            padding-right: 8px !important;
            user-select: none;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: background-color 0.2s ease;
          }

          th:not(.checkbox-column):not(:last-child)::after {
            content: '';
            position: absolute;
            right: 0;
            top: 25%;
            height: 50%;
            width: 1px;
            background-color: var(--admincat-color-grey-20);
          }

          th:not(.checkbox-column):hover {
            background-color: var(--admincat-color-grey-20);
          }

          th:hover .resize-handle {
            background: var(--admincat-medium-color);
            height: 80%;
            top: 10%;
            width: 4px;
            transition: all 0.2s ease;
          }

          th:last-child .resize-handle {
            display: none;
          }

          .resize-handle:hover,
          .resize-handle:active {
            background: var(--admincat-color-primary) !important;
            opacity: 1;
          }

          th.checkbox-column {
            padding-right: 4px !important;
            text-align: center;
          }

          td {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .resize-handle:active::before {
            content: '';
            position: absolute;
            top: -100vh;
            bottom: -100vh;
            left: 0;
            right: 0;
            background: var(--admincat-color-primary);
            opacity: 0.2;
          }

          table {
            table-layout: fixed !important;
          }

          input[type="checkbox"] {
            display: block;
            margin: 3px auto !important;
          }

          thead tr {
            background-color: var(--admincat-color-grey-1);
          }

          thead {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          }

          /* Add styles for sort indicators */
          th:not(.checkbox-column) {
            cursor: pointer;
          }

          th:not(.checkbox-column):hover {
            background-color: var(--admincat-color-grey-20);
          }
        `}
      </style>
    </>
  );
};

export default compose(
  withMail,
  withUserDefaultSpace,
  withUserPreferences,
  withTranslation,
)(MailList);
