import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import paypalImage from '../../assets/img/paypal.png';
import layersImage from '../../assets/img/layers.png';
import breifCaseImage from '../../assets/img/briefcase.png';
import swal from 'sweetalert';
import compose from 'lodash/fp/compose';
import { withTranslation, withAccount, withUserPreferences } from 'components/hoc';
import axios from '../../axios';
import { Button, ButtonLink } from '../common/FormItems';
import API from 'actions/api';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ENABLE_USER_SUBSCRIPTION_PLAN } from 'actions';
import { useDispatch } from 'react-redux';

const StyledButtonLink = styled(ButtonLink)`
  position: absolute;
  left: 0;
  span {
    text-transform: uppercase;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background: #f4f7fa;
  background: var(--admincat-color-grey-1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const SubContainer = styled.div`
  // background: #fff;
  background: var(--admincat-color-grey-1);
  padding: 2.5% 10%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1% 50% 1% 50%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`;

const SubscriptionHeader = styled.h2`
  color: #333;
  text-align: center;
  margin-bottom: 10%;
  color: var(--admincat-color-grey-2);
`;

const SubscriptionCardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const TitleAndIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SubscriptionCard = styled.div`
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  color: ${({ inverted }) => (inverted ? '#FFFFFF' : '#317ED9')};
  background-color: ${({ inverted }) =>
    inverted ? '#317ed9' : 'var(--admincat-color-grey-1)'};
  border: ${({ selected }) => (selected ? '4px solid #FE9902' : '1px solid #ccc')};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  width: 350px;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
`;

const SubscriptionPrice = styled.div`
  font-size: 24px;
  color: ${({ inverted }) => (inverted ? '#FFFFFF' : '#317ed9')};
  margin: 10px 0;
`;

const SubscriptionFeature = styled.p`
  color: ${({ inverted }) => (inverted ? '#FFFFFF' : '#317ed9')};
  margin: 10px 0 20px 0;
`;

const PayLineContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 10px;
`;

const PayButton = styled(Button)`
  background-color: #1364c5;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  width: 150px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const PaymentMethods = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  height: 30px;
`;

const PaymentMethodLogo = styled.img`
  width: 90px;
`;

const IconContainer = styled.div`
  width: 39px;
  height: 39px;
  background-color: #1364c5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const IconImage = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${(props) => `${props.url}`});
  background-size: cover;
`;

const SubscriptionTitle = styled.p`
  font-size: 25px;
  color: ${({ inverted }) => (inverted ? '#FFFFFF' : '#317ed9')};
  margin-bottom: 15px;
`;

const Dropdown = styled.select`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  cursor: pointer;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const SupportedDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding: 15px;
  // color: var(--admincat-color-grey-2);
`;

const PriceVariationNote = styled.p`
  color: #666; // Choose a color that fits your design
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  color: var(--admincat-color-grey-2);
`;

const BannerContainer = styled.div`
  background-color: #e6f3ff;
  border: 1px solid #b3d9ff;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const BannerText = styled.p`
  color: #0066cc;
  font-size: 16px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

function capitalizeFirstLetter(string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const CustomDropdown = withTranslation((props) => {
  const { value, setValue, maxNumber, tr } = props;

  const options = Array.from({ length: maxNumber }, (_, i) => i + 1);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dropdown value={value} onChange={handleChange} aria-label="Select duration">
      <option value="" disabled>
        {tr('Select duration')}
      </option>
      {options.map((number) => (
        <option key={number} value={number}>
          {number}
        </option>
      ))}
    </Dropdown>
  );
});

const CircleIcon = ({ urlImage }) => {
  return (
    <IconContainer>
      <IconImage url={urlImage} />
    </IconContainer>
  );
};


const SubscriptionExpiredBanner = ({ tr, subscriptionEndDate }) => {
  return (
    <BannerContainer>
      <BannerText>
        {tr('You see this page, because your subscription has ended on')}{' '}
        {subscriptionEndDate}.{' '}
        {tr(
          'Please renew your subscription, if you like to keep using our services. Thank you!',
        )}
      </BannerText>
    </BannerContainer>
  );
};

const SubscriptionCardContainer = withTranslation((props) => {
  const {
    paymentTimeType = 'monthly',
    selectedCard,
    selectCard,
    unitAmount,
    featureText = '',
    showPriceVariation = false,
    inverted = false,
    urlImage,
    value,
    setValue,
    maxNumber,
    monthCost = '',
    tr,
  } = props;

  return (
    <SubscriptionCard
      selected={paymentTimeType == selectedCard}
      onClick={selectCard}
      inverted={inverted}
    >
      <SubscriptionCardHeader>
        <TitleAndIconContainer>
          <CircleIcon urlImage={urlImage} />
        </TitleAndIconContainer>
      </SubscriptionCardHeader>
      <SubscriptionCardHeader>
        <SubscriptionPrice
          inverted={inverted}
        >{`${unitAmount} € /`}</SubscriptionPrice>
        <TitleAndIconContainer>
          <SubscriptionTitle inverted={inverted}>
            &nbsp;{`${capitalizeFirstLetter(paymentTimeType)}`}
          </SubscriptionTitle>
        </TitleAndIconContainer>
      </SubscriptionCardHeader>
      <SubscriptionFeature
        inverted={inverted}
      >{`${featureText}`}</SubscriptionFeature>
      <PayLineContainer>
        <CustomDropdown value={value} setValue={setValue} maxNumber={maxNumber} />
      </PayLineContainer>
      {showPriceVariation && (
        <PriceVariationNote>
          {`${tr('For subscription of')} ${value} ${tr('month(s)')}  ${tr(
            'you will be charged at',
          )} €${monthCost} ${tr('per month')}`}
        </PriceVariationNote>
      )}
    </SubscriptionCard>
  );
});

const PaymentPage = ({ tr, fd, subscriptionEndDate, isPaymentNeeded }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [yearCost, _] = useState(19);
  const [monthCost, setMonthCost] = useState(2);
  const [loading, setLoading] = useState(false);
  const [showPriceVariation, setShowPriceVariation] = useState(false);
  

  console.log('isPaymentNeeded: ', isPaymentNeeded);
  console.log('subscriptionEndDate: ', subscriptionEndDate);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get('action');
    const token = queryParams.get('token');

    let message = '';
    let messageDetails = '';
    let type = '';

    if (action) {
      // setLoading(true);
      if (action === 'cancelled') {
        message = tr('Transaction Cancelled');
        messageDetails = tr(
          'The transaction has been cancelled, you will be redireced back',
        );
        type = 'error';
      } else if (action === 'approved') {
        message = tr('Transaction Successful');
        messageDetails = tr(
          'The transaction was successful, you will be redirected back',
        );
        type = 'success';
      }

      // Make API call to cancel the transaction
      if (action == 'approved' || action == 'cancelled') {
        axios
          .post(`${API}/payment/payment_completed/`, { token, action })
          .then((response) => {
            // need to dispatch to the redux store that user has an active plan
            if (action == 'approved') {
              dispatch({ type: ENABLE_USER_SUBSCRIPTION_PLAN, payload: {} });

              // update local storage,
              let user = JSON.parse(localStorage.getItem('user'));
              user.has_active_plan = true;
              localStorage.setItem('user', JSON.stringify(user));
            }

            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error('Error confirming transaction:', error);
          });
      }

      swal(tr(message), tr(messageDetails), type).then(() => {
        history.push('/dashboard/settings');
      });
    }
  }, [location, tr]);

  const selectCard = (card) => {
    setSelectedCard(card);
    if (card == tr('monthly')) {
      setYear(''); // if month is selected, clear year
    }
    if (card == tr('yearly')) {
      setMonth(''); // if year is selected, clear month
    }
  };

  const setMonthValue = (value) => {
    const numericValue = Number(value);
    setMonth(numericValue);
    setYear('');

    setMonthCost(2);

    if (Number.isFinite(numericValue) && numericValue == 1) {
      setShowPriceVariation(true);
      setMonthCost(3.5);
    } else if (Number.isFinite(numericValue) && numericValue == 2) {
      setShowPriceVariation(true);
      setMonthCost(2.5);
    } else {
      setShowPriceVariation(false);
    }
  };

  const setYearValue = (value) => {
    setYear(value);
    setMonth('');
  };

  const pay = async () => {
    try {
      setLoading(true);

      if (selectedCard == tr('monthly')) {
        if (!month) {
          swal(tr('Warning!'), tr(`Please select number of months.`), 'warning');
          return;
        }
      } else if (selectedCard == tr('yearly')) {
        if (!year) {
          swal(tr('Warning!'), tr(`Please select number of years.`), 'warning');
          return;
        }
      }

      const response = await axios.post(`${API}/payment/initiate_payment/`, {
        subscription_type:
          selectedCard.toLowerCase() == tr('monthly').toLowerCase()
            ? 'month'
            : 'year',
        amount:
          selectedCard.toLowerCase() == tr('monthly').toLowerCase()
            ? monthCost
            : yearCost,
        duration:
          selectedCard.toLowerCase() == tr('monthly').toLowerCase() ? month : year,
        url: window.location.href,
      });

      swal(
        tr('Success!'),
        tr(
          'Your payment has been initiated, you will be redirected to paypal to complete this payment',
        ),
        'success',
      ).then(() => {
        const data = response.data;

        // Find the "payer action" URL from the response
        const approveLink = data.links.find((link) => link.rel === 'payer-action');

        if (approveLink && approveLink.href) {
          // Redirect the user to PayPal to approve the payment
          window.location.href = approveLink.href;
        } else {
          // Handle the case where no "approve" link is found
          swal(tr('Error!'), tr('Unable to find approval URL.'), 'error');
          return;
        }
      });
    } catch (error) {
      swal(
        tr('Error!'),
        tr('There was an issue creating this payment. Please contact administrator'),
        'error',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <SubContainer>
        {isPaymentNeeded && (
          <SubscriptionExpiredBanner
            tr={tr}
            subscriptionEndDate={fd(subscriptionEndDate)}
          />
        )}
        <SubscriptionHeader>{tr('Select and make subscription')}</SubscriptionHeader>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <SubscriptionCardContainer
            selectedCard={selectedCard}
            paymentTimeType={tr('monthly')}
            selectCard={() => selectCard(tr('monthly'))}
            unitAmount={monthCost}
            showPriceVariation={showPriceVariation}
            inverted={false}
            iconName="paypal"
            featureText={tr('We are flexible, just pay for the months you want')}
            urlImage={layersImage}
            value={month}
            setValue={setMonthValue}
            monthCost={monthCost}
            maxNumber={9}
          />
          <SubscriptionCardContainer
            selectedCard={selectedCard}
            paymentTimeType={tr('yearly')}
            selectCard={() => selectCard(tr('yearly'))}
            unitAmount={yearCost}
            iconName="cc paypal"
            inverted={true}
            featureText={tr(
              'You save 21% with this option. Choose the number of periods',
            )}
            urlImage={breifCaseImage}
            value={year}
            setValue={setYearValue}
            maxNumber={3}
          />
        </div>
        <SupportedDiv>
          {year != '' && (
            <p style={{ fontSize: '25px', color: 'var(--admincat-color-grey-2)' }}>
              {`
              ${tr('Your payment choice:')}  ${year} ${
                year > 1 ? tr('years') : tr('year')
              } x ${yearCost} € = ${year * yearCost} €`}
            </p>
          )}
          {month != '' && (
            <p style={{ fontSize: '25px', color: 'var(--admincat-color-grey-2)' }}>{`
            ${tr('Your payment choice:')}  ${month} ${
              month > 1 ? tr('months') : tr('month')
            } x ${monthCost} € = ${month * monthCost} €`}</p>
          )}
        </SupportedDiv>
        <PaymentMethods>
          <PaymentMethodLogo src={paypalImage} alt="PayPal Logo" />
          {(year !== '' || month !== '') && (
            <PayButton isLoading={loading} onClick={pay} name={tr('Pay Now')} />
          )}
        </PaymentMethods>
      </SubContainer>
    </PageContainer>
  );
};

export default compose(
  withAccount,
  withUserPreferences,
  withTranslation,
)(PaymentPage);
