import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import compose from 'lodash/fp/compose';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import OutsideClickHandler from 'react-outside-click-handler';
import Icon from 'components/Icon';
import { withTranslation, withUserPreferences } from 'components/hoc';
import { RadialSeparators } from 'components/Dashboard/Home/utils';
import { getNotificationsThunk } from 'components/Dashboard/thunks';
import { deleteNoificationsService } from 'components/Dashboard/services';
import ExpenseChart from 'components/Dashboard/Home/Elements/Charts/ExpenseChart';
import AssetsVsLiabilities from 'components/Dashboard/Home/Elements/Charts/AssetsVsLiabilities';
import IncomeVsExpenditure from 'components/Dashboard/Home/Elements/Charts/IncomeVsExpenditure';
import styles from './gridLayoutStyles.module.css';
import Top5contractsChart from '../Charts/Top5contractsChart';

const ResponsiveGridLayout = WidthProvider(Responsive);

const layout = [
  { i: 'Email', x: 0, y: 0, w: 1, h: 0.51, maxH: 0.51 },
  { i: 'Liabilities', x: 1, y: 0, w: 1, h: 0.51, maxH: 0.51 },
  { i: 'Assets', x: 2, y: 0, w: 1, h: 0.51, maxH: 0.51 },
  { i: 'Documents', x: 3, y: 0, w: 1, h: 0.51, maxH: 0.51 },
  { i: 'contracts_document_notification', x: 4, y: 0, w: 2, h: 1.56, maxH: 1.56 },

  { i: 'Space_group', x: 0, y: 0.51, w: 2, h: 1.049, maxH: 1.049 },
  { i: 'Top_5_contracts', x: 2, y: 0.51, w: 2, h: 1.049, maxH: 1.049 },

  { i: 'Expenditure_vs_income', x: 0, y: 1.02, w: 1, h: 0.51, maxH: 0.51 },
  { i: 'Liabilities_VS_assets', x: 1, y: 1.02, w: 1, h: 0.51, maxH: 0.51 },
  { i: 'Storage', x: 2, y: 1.02, w: 1, h: 0.51, maxH: 0.51 },

  { i: 'Line_chart', x: 0, y: 1.559, w: 4, h: 1.5, maxH: 1.5 },
  { i: 'Donut_chart', x: 4, y: 1.56, w: 2, h: 2, maxH: 2 },
];

const showDefaultElements = {
  Email: true,
  Liabilities: true,
  Assets: true,
  Documents: true,
  Space_group: true,
  Storage: true,
  Top_5_contracts: true,
  contracts_document_notification: true,
  Expenditure_vs_income: true,
  Liabilities_VS_assets: true,
  Line_chart: true,
  Donut_chart: true,
};

const Grids = ({
  storageCounter,
  notifications,
  dashboard_summary,
  tr,
  fd,
  fn,
  dateFormat,
  ...props
}) => {
  const [showSetting, setShowSetting] = useState(false);
  // const [layoutState, setLayoutState] = useState(null);

  // const [layoutState, setLayoutState] = React.useState(() => {
  //   const savedLayouts = localStorage.getItem('grid-layout-v3');
  //   return savedLayouts ? JSON.parse(savedLayouts) : { lg: 996, md: 768, sm: 280};
  // });
  const [layoutState, setLayoutState] = useState(() => {
    const savedLayouts = localStorage.getItem('grid-layout-v3');
    return savedLayouts ? JSON.parse(savedLayouts) : layout;
  });

  // const [layoutState, setLayoutState] = useState(layout);
  const [showElements, setShowElements] = useState(null);
  const [removedElement, setRemovedElement] = useState(null);
  const dispatch = useDispatch();
  // const btnRef = useRef();

  const PROGRESS_VALUE =
    dashboard_summary?.total_storage_in_use?.slice(-2) == 'MB'
      ? parseInt(dashboard_summary?.total_storage_in_use?.slice(0, -2))
      : storageCounter.slice(-2) == 'KB'
      ? parseInt(dashboard_summary?.total_storage_in_use?.slice(0, -2)) / 1024
      : dashboard_summary?.total_storage_in_use?.slice(-2) == 'GB'
      ? parseInt(dashboard_summary?.total_storage_in_use?.slice(0, -2)) * 1024
      : 0;


    useEffect(() => {
        const savedLayouts = localStorage.getItem('grid-layout-v3');
        if (savedLayouts) {
          setLayoutState(JSON.parse(savedLayouts));
        } else {
          localStorage.setItem('grid-layout-v3', JSON.stringify(layout));
        }
    }, []);

  // useEffect(() => {
  //   const savedLayouts = localStorage.getItem('grid-layout-v3');
  //   try {
  //     setLayoutState(JSON.parse(savedLayouts));
  //   } catch (err) {
  //     console.log('Error: ', err.message);
  //   }
  // }, [layoutState?.length]);

  useEffect(() => {
    const savedLayouts = localStorage.getItem('grid-layout-v3');
    try {
      const parsedSavedLayouts = JSON.parse(savedLayouts);
      const filteredLayout = parsedSavedLayouts?.filter(
        (el) => !removedElement?.includes(el.i),
      );
      localStorage.setItem('grid-layout-v3', JSON.stringify(filteredLayout));
      setLayoutState(filteredLayout);
    } catch (err) {
      console.log('Error: ', err.message);
    }
  }, [removedElement]);

  useEffect(() => {
    handleShowElements();
  }, []);

  useEffect(() => {
    const savedLayouts = localStorage.getItem('grid-layout-v3');
    try {
      setLayoutState(JSON.parse(savedLayouts));
      handleSetDashoardElements();
    } catch (err) {
      console.log('Error: ', err.message);
    }
  }, [
    showElements?.Email,
    showElements?.Liabilities,
    showElements?.Assets,
    showElements?.Documents,
    showElements?.Space_group,
    showElements?.Storage,
    showElements?.Top_5_contracts,
    showElements?.contracts_document_notification,
    showElements?.Expenditure_vs_income,
    showElements?.Liabilities_VS_assets,
    showElements?.Line_chart,
    showElements?.Donut_chart,
  ]);

  const handleShowElements = () => {
    const showElementsStorage = localStorage.getItem('show-elements');
    const RemovedElementsStorage = localStorage.getItem('removed-elements');
    if (showElements == null && showElementsStorage == null) {
      setShowElements(showDefaultElements);
      localStorage.setItem('show-elements', JSON.stringify(showDefaultElements));
      localStorage.setItem('removed-elements', JSON.stringify([]));
    } else {
      try {
        setShowElements(JSON.parse(showElementsStorage));
        setRemovedElement(JSON.parse(RemovedElementsStorage));
      } catch (err) {
        console.log('Error: ', err.message);
      }
    }
  };

  const handleSetDashoardElements = () => {
    if (removedElement !== null && showElements !== null) {
      localStorage.setItem('removed-elements', JSON.stringify(removedElement));
      localStorage.setItem('show-elements', JSON.stringify(showElements));
    } else {
      return null;
    }
  };

  const handleDeleteNotification = async (notification_id) => {
    try {
      await deleteNoificationsService(notification_id);
      dispatch(getNotificationsThunk());
    } catch (error) {
      throw error;
    }
  };

  React.useEffect(() => {
    const savedLayouts = localStorage.getItem('grid-layout-v3');
    if (!savedLayouts) {
      localStorage.setItem('grid-layout-v3', JSON.stringify(layoutState));
      localStorage.setItem('all-grid-layout-v3', JSON.stringify(layoutState));
    }
  }, [layoutState]);

  // const getLayouts = React.useCallback(() => {
  //   const savedLayouts = localStorage.getItem('grid-layout-v3');
  //   const parsedSavedLayouts = JSON.parse(savedLayouts);
  //   if (!parsedSavedLayouts || parsedSavedLayouts.length === 0) {
  //     return { lg: layoutState };
  //   } else {
  //     return parsedSavedLayouts;
  //   }
  // }, [layoutState]);

  // const getLayouts = React.useCallback(
  //   (data) => {
  //     const savedLayouts = localStorage.getItem('grid-layout-v3');
  //     const parsedSavedLayouts = JSON.parse(savedLayouts);
  //     if (parsedSavedLayouts?.length == 0 || parsedSavedLayouts == null) {
  //       localStorage.setItem('grid-layout-v3', JSON.stringify(layoutState));
  //       localStorage.setItem('all-grid-layout-v3', JSON.stringify(layoutState));
  //       setLayoutState(layoutState);
  //       return { lg: layoutState };
  //     } else {
  //       return parsedSavedLayouts;
  //     }
  //   },
  //   [layoutState],
  // );

  const handleLayoutChange = React.useCallback((layouts) => {
    setLayoutState(layouts);
    localStorage.setItem('grid-layout-v3', JSON.stringify(layouts));
  }, []);


  const handleOnChangeElements = ({ target: { checked, value } }) => {
    setShowElements({ ...showElements, [value]: checked });
    if (showElements[value] == true) {
      setRemovedElement([...removedElement, value]);
      const savedLayouts = localStorage.getItem('grid-layout-v3');
      const updatedLayouts = JSON.parse(savedLayouts).filter(
        (layout) => layout.i != value,
      );
      try {
        setLayoutState(JSON.parse(savedLayouts));
        handleLayoutChange(updatedLayouts);
        setLayoutState(updatedLayouts);
      } catch (err) {
        console.log('Error: ', err.message);
      }
    } else {
      setRemovedElement(removedElement?.filter((item) => item !== value));
      setShowElements({ ...showElements, [value]: checked });

      // get the new item to add
      let itemToAdd = layout.filter((item) => item.i == value);
      if (itemToAdd.length > 0) {
        const newLayoutState = [...layoutState, itemToAdd[0]];
        localStorage.setItem('grid-layout-v3', JSON.stringify(newLayoutState));
      }
      const savedLayouts = localStorage.getItem('grid-layout-v3');

      try {
        const parsedSavedLayout = JSON.parse(savedLayouts);
        const all_savedLayouts = localStorage.getItem('all-grid-layout-v3');
        const all_savedLayouts_parsed = JSON.parse(all_savedLayouts);
        const findnewItem = all_savedLayouts_parsed?.filter(
          (element) => element?.i == value,
        );

        const updated_grid_items = [...parsedSavedLayout, ...findnewItem];
      } catch (err) {
        console.log('Error: ', err.message);
      }
    }
  };

  const handleRemoveEmail = () => {
    setRemovedElement([...removedElement, 'Email']);
    setShowElements({ ...showElements, Email: false });
  };

  const handleRemoveLiabilities = () => {
    setRemovedElement([...removedElement, 'Liabilities']);
    setShowElements({ ...showElements, Liabilities: false });
  };

  const handleRemoveAssets = () => {
    setRemovedElement([...removedElement, 'Assets']);
    setShowElements({ ...showElements, Assets: false });
  };

  const handleRemoveDocuments = () => {
    setRemovedElement([...removedElement, 'Documents']);
    setShowElements({ ...showElements, Documents: false });
  };

  const handleRemoveSpaceGroup = () => {
    setRemovedElement([...removedElement, 'Space_group']);
    setShowElements({ ...showElements, Space_group: false });
  };

  const handleRemoveStorage = () => {
    setRemovedElement([...removedElement, 'Storage']);
    setShowElements({ ...showElements, Storage: false });
  };

  const handleRemoveTop5contracts = () => {
    setRemovedElement([...removedElement, 'Top_5_contracts']);
    setShowElements({ ...showElements, Top_5_contracts: false });
  };

  const handleRemoveContractsDocumentNotification = () => {
    setRemovedElement([...removedElement, 'contracts_document_notification']);
    setShowElements({ ...showElements, contracts_document_notification: false });
  };

  const handleRemoveExpenditureVsincome = () => {
    setRemovedElement([...removedElement, 'Expenditure_vs_income']);
    setShowElements({ ...showElements, Expenditure_vs_income: false });
  };

  const handleRemoveLiabilitiesVsassets = () => {
    setRemovedElement([...removedElement, 'Liabilities_VS_assets']);
    setShowElements({ ...showElements, Liabilities_VS_assets: false });
  };

  const handleRemoveLineChart = () => {
    setRemovedElement([...removedElement, 'Line_chart']);
    setShowElements({ ...showElements, Line_chart: false });
  };

  const handleRemoveDonutChart = () => {
    setRemovedElement([...removedElement, 'Donut_chart']);
    setShowElements({ ...showElements, Donut_chart: false });
  };

  const translateNotificationString = (str) => {
    const splittedString = str.split(' ');
    const document = tr(splittedString[0]);
    const fileName = splittedString[1];
    const remaining = tr(splittedString.slice(2, 5).join(' '));
    const date = fd(`${splittedString.slice(-1)}`);
    const translatedString = `${document} ${fileName} ${remaining} ${date} ${
      props.userPreLang == 'deu' ? ' ungültig werden' : ''
    }.`;
    return translatedString;
  };

  const translateNotificationContractString = (str) => {
    const splittedString = str.split(' ');

    const firstStrBatch = tr(splittedString.slice(0, 2).join(' '));
    const title = splittedString[2];
    const secondStrBatch = tr(splittedString.slice(3, 7).join(' '));
    const noticePeriod = fd(`${splittedString[7]}`);
    const thirdStrBatch = tr(splittedString.slice(8, 20).join(' '));

    const translatedString = `${firstStrBatch} ${title} ${secondStrBatch} ${noticePeriod} ${thirdStrBatch}.`;

    return translatedString;
  };

  const createElement = (element) => {
    const cardName =
      element?.i == 'Email'
        ? 'Email'
        : element.i == 'Liabilities'
        ? tr('Liabilities')
        : element.i == 'Assets'
        ? tr('Assets')
        : element.i == 'Documents'
        ? tr('Documents')
        : element.i == 'Space_group'
        ? tr('Space Group')
        : element.i == 'Storage'
        ? tr('Storage Space')
        : element.i == 'Top_5_contracts'
        ? tr('Contracts (cost per month)')
        : element.i == 'contracts_document_notification'
        ? tr('Notifications')
        : element.i == 'Expenditure_vs_income'
        ? tr('Income vs. Expenses')
        : element.i == 'Liabilities_VS_assets'
        ? tr('Assets vs. Liabilities')
        : element.i == 'Line_chart'
        ? tr('Income & Expenses Line Chart')
        : element.i == 'Donut_chart'
        ? tr('Expenses Pie Chart')
        : null;

    return (
      <div
        className={styles['grid_cardContainer']}
        key={element.i}
        data-grid={element}
      >
        <div className={styles['grid_cardtop']}>
          <h4 className={styles['grid_cardTitle']}> {cardName} </h4>

          {element.i == 'Email' && (
            <div onClick={handleRemoveEmail}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}

          {element.i == 'Liabilities' && (
            <div onClick={handleRemoveLiabilities}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}

          {element.i == 'Assets' && (
            <div onClick={handleRemoveAssets}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}

          {element.i == 'Documents' && (
            <div onClick={handleRemoveDocuments}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}

          {element.i == 'Space_group' && (
            <div onClick={handleRemoveSpaceGroup}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}

          {element.i == 'Storage' && (
            <div onClick={handleRemoveStorage}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}

          {element.i == 'Top_5_contracts' && (
            <div onClick={handleRemoveTop5contracts}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}

          {element.i == 'contracts_document_notification' && (
            <div onClick={handleRemoveContractsDocumentNotification}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}

          {element.i == 'Expenditure_vs_income' && (
            <div onClick={handleRemoveExpenditureVsincome}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}

          {element.i == 'Liabilities_VS_assets' && (
            <div onClick={handleRemoveLiabilitiesVsassets}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}

          {element.i == 'Line_chart' && (
            <div onClick={handleRemoveLineChart}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}

          {element.i == 'Donut_chart' && (
            <div onClick={handleRemoveDonutChart}>
              <Icon icon="cancel_dash" width={20} height={20} />
            </div>
          )}
        </div>

        <div className={styles['grid_cardBottomContainer']}>
          <div className={styles['cardbottom']}>
            <>
              {element.i == 'Email' && (
                <Icon icon={'email'} width={48} height={48} />
              )}

              {element.i == 'Liabilities' && (
                <Icon icon={'liabilities'} width={48} height={48} />
              )}

              {element.i == 'Assets' && (
                <Icon icon={'assets'} width={48} height={48} />
              )}

              {element.i == 'Documents' && (
                <Icon icon={'documents'} width={48} height={48} />
              )}

              {element.i == 'Storage' && (
                <div style={{ width: 70, height: 70 }}>
                  <CircularProgressbarWithChildren
                    value={PROGRESS_VALUE}
                    maxValue={1024}
                    text={`${((PROGRESS_VALUE / 1024) * 100).toFixed(1)}%`}
                    strokeWidth={10}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                    })}
                  >
                    <RadialSeparators
                      count={12}
                      style={{
                        background: '#fff',
                        width: '2px',
                        // This needs to be equal to props.strokeWidth
                        height: `${10}%`,
                      }}
                    />
                  </CircularProgressbarWithChildren>
                </div>
                // <Icon icon={'storage'} width={48} height={48} />
              )}

              <div className={styles['grid_cardCounter']}>
                {element.i == 'Email' && (
                  <>
                    <div className={styles['grid_emailUnread']}>
                      {dashboard_summary?.total_unread_mails} unread
                    </div>

                    <div className={styles['grid_emailRead']}>
                      {dashboard_summary?.total_read_mails} read
                    </div>
                  </>
                )}

                {element.i == 'Liabilities' && (
                  <>
                    {fn(dashboard_summary?.total_liability_value)?.length > 4 ? (
                      <div className={styles['grid_counter-long']}>
                        {fn(dashboard_summary?.total_liability_value)}
                      </div>
                    ) : (
                      <div className={styles['grid_counter']}>
                        {fn(dashboard_summary?.total_liability_value)}
                      </div>
                    )}

                    <div className={styles['grid_unit']}>
                      {dashboard_summary?.space_currency}
                    </div>
                  </>
                )}

                {element.i == 'Assets' && (
                  <>
                    {fn(dashboard_summary?.total_asset_value)?.length > 4 ? (
                      <div className={styles['grid_counter-long']}>
                        {fn(dashboard_summary?.total_asset_value)}
                      </div>
                    ) : (
                      <div className={styles['grid_counter']}>
                        {fn(dashboard_summary?.total_asset_value)}
                      </div>
                    )}

                    <div className={styles['grid_unit']}>
                      {dashboard_summary?.space_currency}
                    </div>
                  </>
                )}

                {element.i == 'Documents' && (
                  <>
                    {dashboard_summary?.total_documents?.length > 4 ? (
                      <div className={styles['grid_counter-long']}>
                        {dashboard_summary?.total_documents}
                      </div>
                    ) : (
                      <div className={styles['grid_counter']}>
                        {dashboard_summary?.total_documents}
                      </div>
                    )}

                    <div className={styles['grid_unit']}>{'DOCS'}</div>
                  </>
                )}

                {element.i == 'Storage' && (
                  <>
                    <div className={styles['grid_storage']}>
                      {dashboard_summary?.total_storage_in_use} <small>OF</small>
                    </div>

                    <div className={styles['grid_storage']}>
                      1GB <small>IN USE</small>
                    </div>
                  </>
                )}
              </div>
            </>

            {element.i === 'Space_group' && (
              <div className={styles['grid_space_group']}>
                {dashboard_summary?.group_memebers?.length !== 0 ? (
                  dashboard_summary?.group_memebers?.map((person) =>
                    person?.person_picture ? (
                      <img
                        key={person?.id}
                        src={person?.person_picture}
                        width={60}
                        height={60}
                        class={styles['grid_space_group_image']}
                      />
                    ) : (
                      <div
                        key={person?.id}
                        className={styles['grid_space_group_image']}
                      >
                        <Icon
                          key={person?.id}
                          icon="space_group"
                          width={60}
                          height={60}
                        />
                      </div>
                    ),
                  )
                ) : (
                  <div className={styles['infoEmptyState']}>
                    <Icon icon={'people'} width={80} height={80} />

                    <p className={styles['infoEmptyStateText']}>
                      {tr('Please assign a person group to this space')}
                    </p>
                  </div>
                )}
              </div>
            )}

            {element.i == 'Top_5_contracts' && (
              <div className={styles['grid_graph_top5Contract']}>
                {dashboard_summary?.top_5_contracts?.length !== 0 ? (
                  <Top5contractsChart data={dashboard_summary.top_5_contracts} />
                ) : (
                  <div className={styles['infoEmptyState']}>
                    <Icon icon={'top5'} width={80} height={80} />
                    <p className={styles['infoEmptyStateText']}>
                      {tr('No contracts yet!')}
                    </p>
                  </div>
                )}
              </div>
            )}

            {/* {element.i == 'Top_5_contracts' && (
              <div className={styles['grid_graph_top5Contract']}>
                {dashboard_summary?.top_5_contracts?.length !== 0 ? (
                  <Top5contractsChart data={dashboard_summary?.top_5_contracts} />
                ) : (
                  <div className={styles['infoEmptyState']}>
                    <Icon icon={'top5'} width={80} height={80} />

                    <p className={styles['infoEmptyStateText']}>
                      {tr('No contracts yet!')}
                    </p>
                  </div>
                )}
              </div>
            )} */}

            {element.i === 'contracts_document_notification' && (
              <div className={styles['grid_notification']}>
                {notifications?.length !== 0 ? (
                  <ul>
                    {notifications?.map((notification) => (
                      <li key={notification.id}>
                        {notification.document
                          ? translateNotificationString(notification.message)
                          : translateNotificationContractString(
                              notification.message,
                            )}
                        <span
                          onClick={() => handleDeleteNotification(notification.id)}
                        >
                          <Icon icon="cancel_2" width={8} height={8} />
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className={styles['infoEmptyState']}>
                    <Icon icon={'notificationSlash'} width={80} height={80} />

                    <p className={styles['infoEmptyStateText']}>
                      {tr('No notifications yet!')}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* {element.i == 'Storage' && (
            <div className={styles['grid_progressBar']}>
              <Icon icon={'progressBar'} />
            </div>
          )} */}

          {element.i == 'Liabilities_VS_assets' && (
            <div className={styles['grid_cardBottomChart']}>
              {dashboard_summary?.asset_vs_liability?.asset == 0 &&
              dashboard_summary?.asset_vs_liability?.liabilities == 0 ? (
                <div className={styles['infoEmptyState']}>
                  <Icon icon={'barChart'} width={40} height={40} />

                  <p className={styles['infoEmptyStateText']}>
                    {tr('No entries yet!')}
                  </p>
                </div>
              ) : (
                <AssetsVsLiabilities
                  data={[dashboard_summary?.asset_vs_liability]}
                />
              )}
            </div>
          )}

          {element.i == 'Expenditure_vs_income' && (
            <div className={styles['grid_cardBottomChart']}>
              <Icon icon={'incomeVsExpense'} height={100} width={100} />
            </div>
          )}

          {element.i == 'Line_chart' && (
            <div className={styles['grid_graph']}>
              <IncomeVsExpenditure
                dataList_1_year={dashboard_summary?.income_expense_payload}
                incomeCategoryList_1_year={
                  dashboard_summary?.income_category_payload
                }
                expenseCategoryList_1_year={
                  dashboard_summary?.expense_category_payload
                }
                dataList_3_year={
                  dashboard_summary?.income_expense_payload_3_year_interval
                }
                incomeCategoryList_3_year={
                  dashboard_summary?.income_category_payload_3_year_interval
                }
                expenseCategoryList_3_year={
                  dashboard_summary?.expense_category_payload_3_year_interval
                }
                dataList_5_year={
                  dashboard_summary?.income_expense_payload_5_year_interval
                }
                incomeCategoryList_5_year={
                  dashboard_summary?.income_category_payload_5_year_interval
                }
                expenseCategoryList_5_year={
                  dashboard_summary?.expense_category_payload_5_year_interval
                }
                dataList_10_year={
                  dashboard_summary?.income_expense_payload_10_year_interval
                }
                incomeCategoryList_10_year={
                  dashboard_summary?.income_category_payload_10_year_interval
                }
                expenseCategoryList_10_year={
                  dashboard_summary?.expense_category_payload_10_year_interval
                }
              />
            </div>
          )}

          {element.i == 'Donut_chart' && (
            <div className={styles['grid_graph']}>
              {dashboard_summary?.expense_chart_payload == 0 ? (
                <div className={styles['infoEmptyState']}>
                  <Icon icon={'pieChart'} height={150} width={150} />

                  <p className={styles['infoEmptyStateText']}>
                    {tr('No entries yet!')}
                  </p>
                </div>
              ) : (
                <ExpenseChart
                  dataList_1_year={
                    dashboard_summary?.expense_chart_payload_1_years_interval
                  }
                  dataList_3_year={
                    dashboard_summary?.expense_chart_payload_3_years_interval
                  }
                  dataList_5_year={
                    dashboard_summary?.expense_chart_payload_5_years_interval
                  }
                  dataList_100_year={dashboard_summary?.expense_chart_payload}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <OutsideClickHandler
        onOutsideClick={() => {
          setShowSetting(false);
        }}
      >
        <div className={styles['settingsContainer']}>
          <div onClick={() => setShowSetting(!showSetting)}>
            <Icon icon="settingsIcon" width={30} height={30} />
          </div>

          {showSetting && (
            <div className={styles['settingOptionsContainer']}>
              <label className={styles['dropdown']}>
                {tr('Email')}
                <input
                  type="checkbox"
                  id="Email"
                  name="Email"
                  value={'Email'}
                  checked={showElements?.Email}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>

              <label className={styles['dropdown']}>
                {tr('Liabilities')}
                <input
                  type="checkbox"
                  id="Liabilities"
                  name="Liabilities"
                  value={'Liabilities'}
                  checked={showElements?.Liabilities}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>

              <label className={styles['dropdown']}>
                {tr('Assets')}
                <input
                  type="checkbox"
                  id="Assets"
                  name="Assets"
                  value={'Assets'}
                  checked={showElements?.Assets}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>

              <label className={styles['dropdown']}>
                {tr('Documents')}
                <input
                  type="checkbox"
                  id="Documents"
                  name="Documents"
                  value={'Documents'}
                  checked={showElements?.Documents}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>

              <label className={styles['dropdown']}>
                {tr('Space Group')}
                <input
                  type="checkbox"
                  id="Space_group"
                  name="Space_group"
                  value={'Space_group'}
                  checked={showElements?.Space_group}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>

              <label className={styles['dropdown']}>
                {tr('Storage Space')}
                <input
                  type="checkbox"
                  id="Storage"
                  name="Storage"
                  value={'Storage'}
                  checked={showElements?.Storage}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>

              <label className={styles['dropdown']}>
                {tr('Top Contracts')}
                <input
                  type="checkbox"
                  id="Top_5_contracts"
                  name="Top_5_contracts"
                  value={'Top_5_contracts'}
                  checked={showElements?.Top_5_contracts}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>

              <label className={styles['dropdown']}>
                {tr('Notifications')}
                <input
                  type="checkbox"
                  id="contracts_document_notification"
                  name="contracts_document_notification"
                  value={'contracts_document_notification'}
                  checked={showElements?.contracts_document_notification}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>

              <label className={styles['dropdown']}>
                {tr('Income vs. Expenses')}
                <input
                  type="checkbox"
                  id="Expenditure_vs_income"
                  name="Expenditure_vs_income"
                  value={'Expenditure_vs_income'}
                  checked={showElements?.Expenditure_vs_income}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>

              <label className={styles['dropdown']}>
                {tr('Assets vs. Liabilities')}
                <input
                  type="checkbox"
                  id="Liabilities_VS_assets"
                  name="Liabilities_VS_assets"
                  value={'Liabilities_VS_assets'}
                  checked={showElements?.Liabilities_VS_assets}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>

              <label className={styles['dropdown']}>
                {tr('Income & Expenses Line Chart')}
                <input
                  type="checkbox"
                  id="Line_chart"
                  name="Line_chart"
                  value={'Line_chart'}
                  checked={showElements?.Line_chart}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>

              <label className={styles['dropdown']}>
                {tr('Expenses Pie Chart')}
                <input
                  type="checkbox"
                  id="Donut_chart"
                  name="Donut_chart"
                  value={'Donut_chart'}
                  checked={showElements?.Donut_chart}
                  onChange={handleOnChangeElements}
                />
                <span className={styles['checkmark']}></span>
              </label>
            </div>
          )}
        </div>
      </OutsideClickHandler>

      <ResponsiveGridLayout
        // layouts={getLayouts()}
        // layouts={{ lg: layout }}

        layouts={{ lg: layoutState }}
        // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }}
        breakpoints={{ lg: 996, md: 768, sm: 280 }}
        cols={{ lg: 6, md: 3, sm: 1 }}
        isResizable={false}
        margin={[20, 20]}
        compactType={'vertical'}
        rowHeight={300}
        allowOverlap={false}
        width={1000}
        onLayoutChange={handleLayoutChange}
        draggableCancel=".non-draggable"
      >
        {layoutState?.map((el) => createElement(el))}
        {/* {layout?.map((el) => createElement(el))} */}
      </ResponsiveGridLayout>
    </div>
  );
};

export default compose(withTranslation, withUserPreferences)(Grids);
