import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import ContentTemplate from 'components/common/ContentTemplate';
import { Input, FormModal, Button } from 'components/common/FormItems';
import {
  withBulkDelete,
  withUserDefaultSpace,
  withUserLayoutChoice,
  withTranslation,
} from 'components/hoc';
import { DnDPortal } from 'components/common/FormItems/DnDPortal';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import {
  TrashFill,
  Clipboard2,
  PlusLg,
  ArrowDownUp,
  ArrowBarLeft,
  CloudUpload,
} from 'react-bootstrap-icons';
import swal from 'sweetalert';
import { Dropdown } from 'components/common/ContentTemplate/TableBody.js';
import API from 'actions/api';
import { useLocation } from 'react-router-dom';
import axios from '../../../../../axios';
import { setMsgInfo } from 'actions/msgInfo';
import { useDispatch } from 'react-redux';
import { StyledButton } from '../../../../../components/common/FormItems/Form';

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

const HeaderWrapper = styled.div`
  margin-top: 5px;
  disply: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
`;

const StyledActionButton = styled(Button)`
  background: var(--admincat-color-grey-6);
  border-radius: 2px;
  height: 38px;
  color: ${(props) => props.color || 'var(--admincat-color-grey-7)'};
  border: none;
  font-size: 14px;
  line-height: 10px;
  // remove this styles below,
  // if you want to see the full button when resized
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledContentTemplate = styled(ContentTemplate)`
  div.table-responsive {
    height: auto;
    min-height: 70vh;
    max-height: 70vh;
    overflow-y: auto;
    background: var(--admincat-color-grey-1);
    width: auto;
    max-width: 93%;

    @media screen and (max-width: 768px) {
      height: auto;
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      width: auto;
      background: var(--admincat-color-grey-1);
      max-width: 85%;
    }
    @media screen and (min-width: 1200px) {
      height: auto;
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      background: var(--admincat-color-grey-1);
      width: auto;
      max-width: 96%;
    }
  }
`;

const FileInputWrapper = styled.div`
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border 0.3s ease;
  position: relative;

  &:hover {
    border-color: #4c81be;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const UploadIcon = styled(CloudUpload)`
  font-size: 48px;
  color: #4c81be;
  margin-bottom: 10px;
`;

const UploadText = styled.p`
  margin: 0;
  color: #666;
`;

const FileNameText = styled.p`
  margin: 10px 0 0;
  font-weight: bold;
  color: #4c81be;
`;

const StyledUploadModal = styled(FormModal)`
  &&& {
    z-index: 9999;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const StyledCancelButton = styled(StyledButton)`
  background: #e4f1ff;
  border: solid 1px #e4f1ff;
  color: #4c81be;
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  margin-top: 10px;
  font-size: 14px;
`;

const ErrorList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;

  li {
    margin-bottom: 5px;
    padding: 8px;
    background-color: #fff1f0;
    border-radius: 4px;
    border-left: 3px solid #dc3545;
  }
`;

const TransactionContentTemplate = (props) => {
  const {
    toggleForm,
    bulkDelete = () => {},
    rowIds,
    hasBulkDelete,
    viewData,
    getLayoutColumnProps,
    tableName,
    frontendTableName: fTableName,
    backendTableName: bTableName,
    columns: defaultColumns,
    useAddModalForm,
    userDefaultSpace,
    handleCreateForm,
    isFile,
    msg,
    tr,
    revertView,
    viewName = false,
    gotoDetailPath,
    handleViewForm,
    options,
    goBack,
    setRowDetailToState = () => {},
    executeOnRowClick = () => {},
    handleGoBack = () => {},
    contract = {},
    marginTop,
    refreshTransactions = () => {},
    ...rest
  } = props;

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const FILE_SIZE_LIMIT = 5;

  const frontendTableName = fTableName || tableName;
  const backendTableName = bTableName || tableName;

  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName,
    backendTableName,
    defaultColumns,
  );

  const deleteBulk = async () => {
    if (rowIds.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You have not selected any item to delete`),
        'warning',
      );
      return;
    }
    // make api call to delete an email
    try {
      await bulkDelete(rowIds);
    } finally {
      return;
    }
  };

  const viewTimeDependentName = () => {
    if (rowIds.length == 0) {
      swal(tr('Warning!'), tr(`You haven't selected any item to view.`), 'warning');
      return;
    }
    // history.push('/dashboard/data/base/person/create');
    // navigate to the detail page of the first item
    // leverage the view function in the options prop to navigate to a new page
    let viewOption = options.filter(
      (option) => option.name.toLowerCase() == 'Update Name'.toLowerCase(),
    );
    viewOption = viewOption.length > 0 ? viewOption[0] : () => {};
    const idToView = rowIds[0];

    viewOption.onClick({ id: idToView });
    return;
  };

  const isIncome = location.pathname.includes('finance/income');
  const isExpense = location.pathname.includes('finance/consumption');

  const downloadTemplate = async () => {
    setLoading(true);
    let apiUrl = '';
    if (isIncome) {
      apiUrl = `${API}/dashboard/transactions/download_template?category=income`;
    } else if (isExpense) {
      apiUrl = `${API}/dashboard/transactions/download_template?category=expenses`;
    }

    try {
      const response = await axios.get(apiUrl, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `template_${isIncome ? 'income' : 'expense'}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch(
        setMsgInfo({
          success: false,
          msg: [`Error downloading template, Please try again later`],
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const uploadData = () => {
    setSelectedFile(null);
    setUploadError(null);
    setIsUploading(false);
    setShowUploadModal(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setUploadError(null);
    }
  };

  const [uploadError, setUploadError] = useState(null);

  const handleUpload = async () => {
    setUploadError(null);

    if (!selectedFile) {
      setUploadError(['Please select a file to upload']);
      dispatch(
        setMsgInfo({
          success: false,
          msg: ['Please select a file to upload'],
        }),
      );
      return;
    }

    const maxSize = FILE_SIZE_LIMIT * 1024 * 1024;
    if (selectedFile.size > maxSize) {
      setUploadError([
        `File size exceeds ${FILE_SIZE_LIMIT} MB. Please choose a smaller file.`,
      ]);
      dispatch(
        setMsgInfo({
          success: false,
          msg: [
            `File size exceeds ${FILE_SIZE_LIMIT} MB. Please choose a smaller file.`,
          ],
        }),
      );
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      setIsUploading(true);
      let apiUrl = '';
      if (isIncome) {
        apiUrl = `${API}/dashboard/transactions/upload_transactions?category=income`;
      } else if (isExpense) {
        apiUrl = `${API}/dashboard/transactions/upload_transactions?category=expenses`;
      }
      await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch(
        setMsgInfo({
          success: true,
          msg: ['File uploaded successfully'],
        }),
      );
      setShowUploadModal(false);
      setSelectedFile(null);
      setIsUploading(false);

      refreshTransactions();
    } catch (error) {
      let errorMsg = ['Error uploading file. Please try again.'];
      if (error.response?.data?.errors) {
        errorMsg = Array.isArray(error.response.data.errors)
          ? error.response.data.errors
          : [error.response.data.errors];
      }
      setUploadError(Array.isArray(errorMsg) ? errorMsg.join(', ') : errorMsg);
      dispatch(
        setMsgInfo({
          success: false,
          msg: Array.isArray(errorMsg) ? 'Failed to upload data' : errorMsg,
        }),
      );
    } finally {
      setIsUploading(false);
    }
  };

  const closeUploadModal = () => {
    setShowUploadModal(false);
    setSelectedFile(null);
    setIsUploading(false);
  };

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const onAddClicked = handleCreateForm || toggleForm;

  const fileInputRef = useRef(null);

  const handleFileInputClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFileChange({ target: { files } });
    }
  };

  return (
    <React.Fragment>
      {msg}
      <HeaderWrapper className="flex">
        <ButtonWrapper className="flex" style={{ marginBottom: '15px' }}>
          {useAddModalForm && (
            <StyledActionButton
              className="option-btn"
              name="Add"
              reactIcon={<PlusLg color="#0E8436" size={12} className="mr-2" />}
              onClick={() => onAddClicked()}
              disabled={isFile}
            />
          )}
          {hasBulkDelete && (
            <StyledActionButton
              className="option-btn"
              onClick={deleteBulk}
              reactIcon={<TrashFill color="#F00" size={16} className="mr-3" />}
              name="Delete Marked"
            />
          )}
          <DnDPortal tableName={frontendTableName} columns={layoutColumns} />

          {viewName && (
            <StyledActionButton
              className="option-btn"
              onClick={viewTimeDependentName}
              reactIcon={<Clipboard2 color="#4C818E" size={16} className="mr-3" />}
              name="View Name"
            />
          )}

          <Dropdown
            options={[
              {
                name: 'Download Template',
                onClick: downloadTemplate,
                isLink: false,
              },
              {
                name: isIncome ? 'Upload Income Data' : 'Upload Expense Data',
                onClick: uploadData,
                isLink: false,
              },
            ]}
            tr={tr}
            headerTitle={'Actions'}
            userDefaultSpace={userDefaultSpace}
            loading={loading}
            type={'vertical'}
          />

          {revertView && (
            <StyledActionButton
              className="option-btn"
              onClick={viewTimeDependentName}
              reactIcon={<ArrowDownUp color="#4C818E" size={16} className="mr-3" />}
              name="Revert"
            />
          )}
          {goBack && (
            <StyledButton
              className="option-btn"
              onClick={handleGoBack}
              reactIcon={<ArrowBarLeft color="#4C818E" size={16} className="mr-1" />}
              name="Go Back"
            />
          )}
        </ButtonWrapper>
      </HeaderWrapper>
      {contract?.id && (
        <form class="form-inline">
          <div class="form-group">
            <Input
              value={contract?.contractor_detail?.name}
              name="Contractor"
              label={tr('Contractor')}
              placeholder={tr('Contractor')}
              disabled
            />
          </div>
          <div class="form-group mx-sm-3">
            <Input
              value={contract?.id}
              name="contractId"
              label={tr('Contract Id')}
              placeholder={tr('Contract Id')}
              disabled
            />
          </div>
          <div class="form-group mx-sm-3">
            <Input
              value={contract?.description}
              name="description"
              label={tr('Description')}
              placeholder={tr('Description')}
              disabled
            />
          </div>
        </form>
      )}

      <StyledUploadModal
        title={tr('Upload Data')}
        open={showUploadModal}
        toggleForm={closeUploadModal}
        onSubmit={handleUpload}
        styles={{ height: 'auto' }}
        renderActionButtons={() => (
          <ButtonGroup>
            <StyledCancelButton
              name={tr('Close')}
              onClick={closeUploadModal}
              type="button"
              disabled={isUploading}
            />
            <StyledButton
              name={isUploading ? tr('Uploading...') : tr('Upload')}
              onClick={handleUpload}
              isLoading={isUploading}
              type="button"
              disabled={!selectedFile || isUploading}
            />
          </ButtonGroup>
        )}
      >
        <FileInputWrapper
          onClick={(e) => handleFileInputClick(e)}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <UploadIcon />
          <UploadText>{tr('Click to upload')}</UploadText>
          <UploadText>{tr(`XLSX, XLS (MAX. ${FILE_SIZE_LIMIT}MB)`)}</UploadText>
          {selectedFile && <FileNameText>{selectedFile.name}</FileNameText>}
          <HiddenFileInput
            ref={fileInputRef}
            type="file"
            accept=".xlsx,.xls"
            onChange={(e) => {
              handleFileChange(e);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            disabled={isUploading}
          />
          {uploadError && (
            <ErrorMessage>
              {Array.isArray(uploadError) ? (
                <ErrorList>
                  {uploadError.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ErrorList>
              ) : (
                uploadError
              )}
            </ErrorMessage>
          )}
        </FileInputWrapper>
      </StyledUploadModal>

      <StyledContentTemplate
        {...rest}
        columns={columns}
        hasBulkDelete={hasBulkDelete}
        viewData={viewData}
        rowIds={rowIds}
        showDetailOnRowClick
        setRowDetailToState={setRowDetailToState}
        executeOnRowClick={executeOnRowClick}
      />
    </React.Fragment>
  );
};

TransactionContentTemplate.defaultProps = {
  useAddModalForm: true,
  handleCreateForm: null,
  frontendTableName: null,
  backendTableName: null,
  columns: [],
};

export default compose(
  withBulkDelete,
  withUserLayoutChoice,
  withFileSize,
  withUserDefaultSpace,
  withTranslation,
)(TransactionContentTemplate);
